











































































































































































.s-green{
  background: #198754;
  height: 100%;
  width: 3px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}
.s-red{
  background: #dc3545;
  height: 100%;
  width: 3px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}
.s-blue{
  background: #0d6efd;
  height: 100%;
  width: 3px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

.search-input {
  height: 40px;
}

.el-form--inline .el-form-item {
  margin-right: 0;
}

.checkbox-product {

.el-checkbox {
  display: block;
}
}
